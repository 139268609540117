*,
::after,
::before {
  box-sizing: border-box;
}

/* fonts */
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400&display=swap");

html {
  font-size: 100%;
} /*16px*/

:root {
  /* colors */
  --primary-100: #e2e0ff;
  --primary-200: #c1beff;
  --primary-300: #a29dff;
  --primary-400: #837dff;
  --primary-500: #645cff;
  --primary-600: #504acc;
  --primary-700: #3c3799;
  --primary-800: #282566;
  --primary-900: #141233;

  /* grey */
  --grey-50: #f8fafc;
  --grey-100: #f1f5f9;
  --grey-200: #e2e8f0;
  --grey-300: #cbd5e1;
  --grey-400: #94a3b8;
  --grey-500: #64748b;
  --grey-600: #475569;
  --grey-700: #334155;
  --grey-800: #1e293b;
  --grey-900: #0f172a;
  /* rest of the colors */
  --black: #222;
  --white: #fff;
  --red-light: #f8d7da;
  --red-dark: #842029;
  --green-light: #d1e7dd;
  --green-dark: #0f5132;
  --light-black: #333;

  /* fonts  */
  --headingFont: "Space Mono", sans-serif;
  --bodyFont: "Quicksand", sans-serif;
  --small-text: 0.875rem;
  --extra-small-text: 0.7em;
  /* rest of the vars */
  --backgroundColor: var(--grey-50);
  --textColor: var(--grey-900);
  --borderRadius: 0.25rem;
  --letterSpacing: 1px;
  --transition: 0.3s ease-in-out all;
  --max-width: 1120px;
  --fixed-width: 600px;
  --info-box-blue: #dffdff;
  --info-box-green: #98edac;
  --info-box-purple: #c6c8ee;

  /* box shadow*/
  --shadow-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.light-theme {
  --background-color: #fffaf0;
  --hero-text-color: #333;
  --textColor: #222;
  --searchBoxBorder: #222;
  --searchBoxBorderFocus: #e3869c;
  --singleTokenBorder: #070943;
  --tokenBoxBg: #fff;
  --visitLinkColor: #444;
  --visitLinkColorHover: #111;
  --menuLinkHover: #777;
  --menuLinkVisited: #444;
}
.dark-theme {
  --background-color: #222222;
  --hero-text-color: #fffaf0;
  --textColor: #fff;
  --searchBoxBorder: #999;
  --searchBoxBorderFocus: #e3869c;
  --singleTokenBorder: #666;
  --tokenBoxBg: #222;
  --visitLinkColor: #f0f9ff;
  --visitLinkColorHover: #e3869c;
  --menuLinkHover: #e3869c;
  --menuLinkVisited: #eee;
}
body {
  font-family: var(--bodyFont);
  font-weight: 400;
  line-height: 1.75;
  color: var(--textColor);
}

p {
  margin-bottom: 1.5rem;
  max-width: 40em;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  margin-bottom: 1.38rem;
  font-family: var(--headingFont);
  font-weight: 400;
  line-height: 1.3;
  text-transform: capitalize;
  letter-spacing: var(--letterSpacing);
  color: var(--textColor);
}

h1 {
  margin-top: 0;
  font-size: 3.052rem;
}

h2.logo-text {
  color: var(--textColor);
  font-size: 1.4rem;
  padding: 0;
  font-weight: bold;
  margin: 0;
}

h2 {
  font-size: 2.441rem;
}

h3 {
  font-size: 1.953rem;
}

h4 {
  font-size: 1.563rem;
}

h5 {
  font-size: 1.25rem;
}

h1.main-heading {
  /* font-family: "Quicksand", "sans-serif"; */
  font-size: 3.6rem;
  text-transform: uppercase;
  color: var(--hero-text-color);
  font-weight: 700;
}

p.hero-text {
  font-family: var(--headingFont);
  color: var(--hero-text-color);
  text-transform: uppercase;
}

small,
.text-small {
  font-size: var(--small-text);
}

a {
  text-decoration: none;
}
ul {
  list-style-type: none;
  padding: 0;
}

.img {
  width: 100%;
  display: block;
  object-fit: cover;
}

alerts .alert {
  padding: 0.375rem 0.75rem;
  margin-bottom: 1rem;
  border-color: transparent;
  border-radius: var(--borderRadius);
}

.alert-danger {
  color: var(--red-dark);
  background: var(--red-light);
}
.alert-success {
  color: var(--green-dark);
  background: var(--green-light);
}
/* form */
/* 
.form {
  width: 90vw;
  max-width: var(--fixed-width);
  background: var(--white);
  border-radius: var(--borderRadius);
  box-shadow: var(--shadow-2);
  padding: 2rem 2.5rem;
  margin: 3rem auto;
}
.form-label {
  display: block;
  font-size: var(--small-text);
  margin-bottom: 0.5rem;
  text-transform: capitalize;
  letter-spacing: var(--letterSpacing);
}
.form-input,
.form-textarea {
  width: 100%;
  padding: 0.375rem 0.75rem;
  border-radius: var(--borderRadius);
  background: var(--backgroundColor);
  border: 1px solid var(--grey-200);
}

.form-row {
  margin-bottom: 1rem;
}

.form-textarea {
  height: 7rem;
}
::placeholder {
  font-family: inherit;
  color: var(--grey-400);
}
.form-alert {
  color: var(--red-dark);
  letter-spacing: var(--letterSpacing);
  text-transform: capitalize;
} */

.main-links-btn {
  background-color: var(--tokenBoxBg);
  border: 3px solid var(--singleTokenBorder);
  border-radius: 20px;
  height: 3.5rem;
  font-weight: bold;
  color: var(--textColor);
}
.main-links-btn:hover {
  background-color: #e3869c;
  color: var(--textColor);
}
.visit-link {
  color: var(--visitLinkColor);
  border-bottom: 1px dotted var(--visitLinkColor);
}
.visit-link:hover {
  color: var(--visitLinkColorHover);
  border-bottom: 1px dotted var(--visitLinkColorHover);
}

.currency-box {
  background-color: var(--info-box-green);
}
.chain-id-box {
  background-color: var(--info-box-blue);
}
.type-box {
  background-color: var(--info-box-purple);
}
.info-pill {
  color: #111;
  position: relative;
  top: -1px;
}
/* alert */

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.loading {
  width: 6rem;
  height: 6rem;
  border: 5px solid var(--grey-400);
  border-radius: 50%;
  border-top-color: var(--primary-500);
  animation: spinner 0.6s linear infinite;
}
.loading {
  margin: 0 auto;
}
/* title */

.title {
  text-align: center;
}

.title-underline {
  background: var(--primary-500);
  width: 7rem;
  height: 0.25rem;
  margin: 0 auto;
  margin-top: -1rem;
}

/* Navbar styling */
/* 
.navbar > .container {
  max-width: 1080px;
} */
